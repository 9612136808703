import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const FührerKingWaka = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="Führer King Waka" />
        <meta
          name="twitter:image"
          content="http://shoya.co/static/shoyaright_icon_twitter_card_large_summary-ce9c728db92ba633cc195662666f7a8f.png"
        />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <link rel="stylesheet" href="../styles/global.css" />
      <div className="article">
        <Link to="/shoyaright">
          <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p>Führer King Waka</p>
        </div>
        <div className="byLine">
          <p> By: Lil Shoyaright! (Pub. Feb. 27th, 2023) </p>
        </div>
        <div className="articleBody">
          <p>
            After a year spent on the brink of WWIII, I, in my vast wisdom, have
            decided to further destabilize the global geopolitical climate.{" "}
          </p>
          <p>
            People often paint the world order in broad strokes. There’s the
            “First World” and the “Third World”, or nowadays “the Global North”
            and “the Global South”. But if you dig a bit deeper, there are a
            handful of nations that defy that simple categorization. Politicos
            refer to them as the "
            <a
              href="https://en.wikipedia.org/wiki/BRICS"
              target="__blank"
              rel="noopener noreferer"
            >
              BRICS
            </a>
            " nations, an acronym referring of the five foremost “emerging
            economies” on the planet: Brazil, Russia, India, China, and South
            Africa. These nations relate to one another and other world powers
            in complex ways. I decided to simplify it.
          </p>
          <p>
            At the start of the year I met with the heads of state of these five
            nations on{" "}
            <a
              href="https://shoya.co/shoyaright/vol1/mt-shoya/"
              target="__blank"
              rel="noopener noreferer"
            >
              Mt. Shoya
            </a>
            . We sat at a massive round table for dinner: Lula ordered the
            curried goat, Xi ordered the Caesar salad, Ramaphosa ordered the
            falafel gyro, Modi ordered the smoked salmon, and Putin ordered the
            hot wings. There were eleven chairs at the table, the six of us sat
            like{" "}
            <a
              href="https://en.wikipedia.org/wiki/Dining_philosophers_problem"
              target="__blank"
              rel="noopener noreferer"
            >
              dining philosophers
            </a>{" "}
            with empty chairs between us.
          </p>
          <p>When dinner was cleared, I made the announcement:</p>
          <p>
            ME: At the end of this month, you will formally declare a{" "}
            <a
              href="https://en.wikipedia.org/wiki/Supranational_union"
              target="__blank"
              rel="noopener noreferer"
            >
              supranational union
            </a>{" "}
            between your nations. You wil consolidate all economic, political,
            and military organizations under one flag.
          </p>
          <p>The leaders looked at one another, baffled.</p>
          <p>
            ME: You will also invite four more nations to join this new body.
          </p>
          <p>
            I rang a small bell. From the shadows, the heads of state of Qatar,
            Uruguay, Angola, and Djibouti emerged. They sat amongst their peers.
            Only one seat remained empty then, directly to my right.
          </p>
          <p>
            ME: And as the supreme leader of this alliance, the individual who
            will wield unspeakable power…
          </p>
          <p>
            The leaders all puffed out their chests and straightened their
            appearance, preparing to accept the honor.
          </p>
          <p>
            A string quartet began to play the opening phrase of <br />
            <a
              href="https://www.youtube.com/watch?v=WkkC9cK8Hz0"
              target="__blank"
              rel="noopener noreferer"
            >
              Hard In Da Paint
            </a>
            .
          </p>
          <p>ME: You will name Waka Flocka Flame as Secretary General. </p>
          <p>
            Waka entered and sat in the remaining seat. His demeanor was stern,
            he examined the others closely, one by one.
          </p>
          <p>WAKA: (sotto voce) BRICSQUAD.</p>
          <p>
            Ard so welcome to Vol. 4 I’m getting a late start this year cuz I
            was yehmean getting to the money like I do!!! Actually that’s a lie
            I just been crying a lot, life is hard.
          </p>
        </div>
        <p className="plug">
          If you enjoyed this article, consider{" "}
          <a href="https://www.patreon.com/join/shoyaright?">
            becoming a patron
          </a>{" "}
          or making a{" "}
          <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">
            one-time pledge
          </a>
          . Support corporate journalism!
        </p>
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2020 - &infin;</p>
        </div>
      </div>
    </div>
  )
}
export default FührerKingWaka
